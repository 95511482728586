import React, { useState, useEffect, useContext} from 'react';
import { AppContext } from '../contexts/context';

// Material UI
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';

import AssessmentIcon from '@mui/icons-material/Assessment';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TuneIcon from '@mui/icons-material/Tune';

import SyncLockIcon from '@mui/icons-material/SyncLock';

import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import UpdateIcon from '@mui/icons-material/Update';

//Components
import { getDateTimeString } from '../components/AppUtils';

// Tools
import { QRCodeSVG } from 'qrcode.react';
import LZString from 'lz-string';
import { updatedDiff } from 'deep-object-diff';

const ShareDialog = () => {
  const encoder = new TextEncoder();
  const myURL = new URL(window.location.href);

  const [ state, dispatch ] = useContext(AppContext);

  const getDefaultConfigName = () => {
    return `${state.nfcSchema.$id.split("/")[2]}-${getDateTimeString()}`;
  };

  const [ open, setOpen ] = useState(false);
  const [ shareURL, setShareURL ] = useState('');
  const [ shareURLlength, setShareURLlength ] = useState(0);
  const [ showQrCode, setShowQrCode ] = useState(false);
  const [ cboxShareValues, setCboxShareValues ] = useState(false);
  const [ cboxShareChangedParameters, setCboxShareChangedParameters ] = useState(false);
  const [ cboxShareChangedParametersDisabled, setCboxShareChangedParametersDisabled ] = useState(true);
  const [ cboxShareCredentialsProtectedParameters, setCboxShareCredentialsProtectedParameters ] = useState(false);
  const [ cboxShareCredentialsProtectedParametersDisabled, setCboxShareCredentialsProtectedParametersDisabled ] = useState(true);
  const [ expanded, setExpanded ] = useState('panel1');
  const [ cboxConfigName, setCboxConfigName ] = useState(getDefaultConfigName());
  const [ cboxConfigNameError, setCboxConfigNameError] = useState(false);
  const [ cboxConfigNameHelperText, setCboxConfigNameHelperText ] = useState('Enter unique name or description.');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dialogTitle = "Share Configuration";

  const maxQRCodeSize = 2300; // Max 4296 Alphanumeric chars.
  var qsExportInit = '?D='
  var qsName = '&N='

  if (myURL.search.length) {
    qsExportInit = '&D='
  } else {
    qsExportInit = '?D='
  }

  var qsDataV = null;
  var qsDataVP = null;
  var qsDataP = null;
  var qsDataPR = null;
  var qsDataPP = null;
  var qsDataCP = null;

  function diff(obj1, obj2) {
    const result = {};
    if (Object.is(obj1, obj2)) {
        return undefined;
    }
    if (!obj2 || typeof obj2 !== 'object') {
        return obj2;
    }
    Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
        if(obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
            result[key] = obj2[key];
        }
        if(typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
            const value = diff(obj1[key], obj2[key]);
            if (value !== undefined) {
                result[key] = value;
            }
        }
    });
    return result;
  }

  function countNonEmptyStrings(arr) {
    return arr.filter(str => str !== "").length;
}

  function extractValuesDiff(objIn, objLength) {
    let valuesArray = Array(objLength).fill("");

    console.debug("ShareDialog: extractValuesDiff", objIn, objLength);

    if (typeof objIn === 'object' && objIn !== null) {
      for (let key in objIn) {
        if (Object.prototype.hasOwnProperty.call(objIn, key)) {
          let nestedObj = objIn[key];
          for (let nestedKey in nestedObj) {
            if (Object.prototype.hasOwnProperty.call(nestedObj, nestedKey)) {
              let index = parseInt(nestedKey, 10);
              if (index < objLength) {
                  valuesArray[index] = nestedObj[nestedKey];
              }
            }
          }
        }
      }
    }

    console.debug("ShareDialog: valuesArray", countNonEmptyStrings(valuesArray));
    if (countNonEmptyStrings(valuesArray) !== 0) {
      return valuesArray;
    }
    return [];
  }

  function extractValues(jsonObject) {
    const valuesArray = [];
    // Rekursive Funktion zum Durchlaufen des JSON-Objekts
    function scanObject(obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          scanObject(obj[key]); // Rekursion für verschachtelte Objekte
        } else {
          valuesArray.push((obj[key]));
        }
      }
    }
    // Starte die Rekursion mit dem Wurzelobjekt
    scanObject(jsonObject);
    return valuesArray;
  }

  const handleAbortClick = () => {
    dispatch({type: 'app/ui/dialog/default/close'});
  };

  const handleOpenLinkClick = () => {
    const newWindow = window.open(shareURL, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const showQrCodeSwitchHandler = (event) =>{
    setShowQrCode(event.target.checked)
  }

  const cboxShareValuesTogglehandler = (event) => {
    setCboxShareValues(event.target.checked);
  };

  const cboxShareChangedParametersTogglehandler = (event) => {
    setCboxShareChangedParameters(event.target.checked);
  };

  const cboxShareCredentialsProtectedTogglehandler = (event) => {
    setCboxShareCredentialsProtectedParameters(event.target.checked);
  };

  const handlShareClick = () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      console.debug("Congrats! Your browser supports Web Share API");
      navigator
        .share({
          url: shareURL
        })
        .then(() => {
          console.debug("Sharing successfull");
        })
        .catch(() => {
          console.debug("Sharing failed");
        });
    } else {
      console.error("Sorry! Your browser does not support Web Share API");
      dispatch({
        type: 'app/ui/dialog/error',
        title: `Web Share Error`,
        text: `Web Share API is not supported on you Device!`
      });
    }
  };

  const handleConfigName = (event) => {

    const inputValue = event.target.value;
    const regexPattern = /^[a-zA-Z0-9+-]*$/;

    if (inputValue.length > 20 || inputValue.length < 5) {
      setCboxConfigNameError(true);
      setCboxConfigNameHelperText('Name must be between 5 and 20 characters long.');
    } else if (!regexPattern.test(inputValue)) {
      setCboxConfigNameError(true);
      setCboxConfigNameHelperText('Input does not match the required pattern');
    } else {
      setCboxConfigNameError(false);
      setCboxConfigNameHelperText('');
    }

    setCboxConfigName(inputValue);
  };

  const handleConfigNameUpdate = (event) => {
    setCboxConfigName(getDefaultConfigName());
    setCboxConfigNameError(false);
  };

  const handleUrlCopy = () => {
    navigator.clipboard.writeText(shareURL)
    dispatch({
      type: 'app/ui/snackbar/show',
      value: "Link saved to clipboard."
    });
  }

  // Dialog Controll
  useEffect(() => {
    if (state.uiDialog === 'share') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.uiDialog]);

  // Process DataExport
  useEffect(() => {
    var shareDataObject = {};

    console.debug("DataExport - nfcDataSchema: ", state.nfcSchema);
    console.debug("DataExport - nfcDataV: ", state.nfcDataV);
    console.debug("DataExport - nfcDataVP: ", state.nfcDataVP);
    console.debug("DataExport - nfcDataP: ", state.nfcDataP);
    console.debug("DataExport - nfcDataPP: ", state.nfcDataPP);
    console.debug("DataExport - nfcDataPR: ", state.nfcDataPR);
    console.debug("DataExport - nfcDataCP: ", state.nfcDataCP);

    var qsDataChangesP = encoder.encode(JSON.stringify(updatedDiff(state.originP, state.nfcDataP)))
    var qsDataChangesPP = encoder.encode(JSON.stringify(updatedDiff(state.originPP, state.nfcDataPP)))
    var qsDataChangesPR = encoder.encode(JSON.stringify(updatedDiff(state.originPR, state.nfcDataPR)))

    if ((qsDataChangesP.length > 2)
      || (qsDataChangesPR.length > 2)
      || (qsDataChangesPP.length > 2)) {
      setCboxShareChangedParametersDisabled(false);
    }

    if (state.settingAuthenticated === true) {
      setCboxShareCredentialsProtectedParametersDisabled(false);
    }

    qsDataV = extractValues(state.nfcDataV);
    qsDataVP = extractValues(state.nfcDataVP);
    qsDataP = extractValues(state.nfcDataP);
    qsDataPP = extractValues(state.nfcDataPP);
    qsDataPR = extractValues(state.nfcDataPR);
    qsDataCP = extractValues(state.nfcDataCP);

    var qsDiffDataP = extractValuesDiff(diff(state.originP, state.nfcDataP), Object.keys(state.nfcDataP.P).length)
    var qsDiffDataPR = extractValuesDiff(diff(state.originPR, state.nfcDataPR), Object.keys(state.nfcDataPR.PR).length)
    var qsDiffDataPP = extractValuesDiff(diff(state.originPP, state.nfcDataPP), Object.keys(state.nfcDataPP.PP).length)

    console.debug("ShareDialog: qsDataV", qsDataV);
    console.debug("ShareDialog: qsDataVP", qsDataVP);
    console.debug("ShareDialog: qsDiffDataP", qsDiffDataP);
    console.debug("ShareDialog: qsDiffDataPR", qsDiffDataPR);
    console.debug("ShareDialog: qsDiffDataPP", qsDiffDataPP);

    shareDataObject.S = state.nfcSchema.$id;

    if (cboxShareCredentialsProtectedParameters) {
      shareDataObject.CP = qsDataCP;
    }

    if (cboxShareValues) {
      shareDataObject.V = qsDataV;
      shareDataObject.VP = qsDataVP;
    }

    if (cboxShareChangedParameters) {
      shareDataObject.P = qsDiffDataP;
      shareDataObject.PP = qsDiffDataPP;
      shareDataObject.PR = qsDiffDataPR;
    } else {
      shareDataObject.P = qsDataP;
      shareDataObject.PP = qsDataPP;
      shareDataObject.PR = qsDataPR;
    }

    // Remove empty arrays
    for (const key in shareDataObject) {
      if (shareDataObject[key].length === 0) {
        delete shareDataObject[key];
      }
    }

    var shareDataString = JSON.stringify(shareDataObject);
    console.debug("ShareDialog: shareDataObject", shareDataObject);
    console.debug("ShareDialog: shareDataString", shareDataString);
    console.debug("ShareDialog: cboxConfigName", cboxConfigName);
    console.debug("ShareDialog: lz-string base64 ..")
    var shareDataCompressedB64 = LZString.compressToEncodedURIComponent(shareDataString);
    console.debug(shareDataCompressedB64)
    setShareURL(`${myURL.href}${qsExportInit}${shareDataCompressedB64}${qsName}${cboxConfigName}`);
    setShareURLlength(shareURL.length);
    console.debug("ShareDialog: shareURLlength", shareURLlength);
  }, [state.uiDialog, cboxShareValues, cboxShareChangedParameters, cboxShareCredentialsProtectedParameters, cboxConfigName]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleAbortClick}
        aria-labelledby="form-dialog-share"
        fullScreen={fullScreen}
      >
        { fullScreen
        ? <>
            <AppBar position="fixed" elevation={0}
             sx={{bgcolor: 'background.paper'}}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleAbortClick}
                  aria-label="close"
                >
                  <CloseIcon sx={{color:'#3b3b3b'}}/>
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1, color:"#3b3b3b" }} variant="h6" component="div">
                  {dialogTitle}
                </Typography>
                <Button
                  onClick={handlShareClick}
                  disabled={cboxConfigNameError ? true : false}
                  autoFocus
                  // color="inherit"
                  color="primary"
                  sx={{color:"#3b3b3b"}}
                  >
                  Share
                </Button>
              </Toolbar>
            </AppBar>
            <Toolbar />
          </>
        : <DialogTitle
            id="form-dialog-share"
            sx={{display:"flex", flexDirection:"column", margin:0, alignItems:"center", justifyContent:"center"}}>
              {dialogTitle}
          </DialogTitle>
        }
        <Box
          sx={{display:"flex", flexDirection:"column", margin:0, padding:1, alignItems:"stretch", justifyContent:"center"}}>
          <Typography sx={{ m:1, flex: 1, color:"#3b3b3b" }} variant="body2" component="div">
            The data is shared as a link, which directly launches the app at the recipient.
          </Typography>
          {shareURLlength < maxQRCodeSize
            ? <Box sx={{m:1}}>
                <QRCodeSVG value={shareURL} height={'100%'} width={'100%'} fgColor={theme.palette.primary.dark} size={500}/>
              </Box>
            : <Typography sx={{ m:1, flex: 1, color:"#3b3b3b" }} variant="body2" component="div">
                QR-Code not available. Check 'Options' to reduce data volume.
              </Typography>
          }
          <Box sx={{m:1}}>
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="shared-config-url">Link to share</InputLabel>
              <FilledInput
                id="shared-config-url"
                disableUnderline={true}
                value={shareURL}
                readOnly={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleUrlCopy}
                      onMouseDown={handleUrlCopy}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant="outlined"
              endIcon={<OpenInNewIcon />}
              onClick={handleOpenLinkClick}
              disabled={cboxConfigNameError ? true : false}
              color="primary"
              fullWidth
            >
              Show
            </Button>
          </Box>
          <List
            sx={{ width: '100%', bgcolor: 'background.paper'}}
            subheader={<ListSubheader sx={{color:state.brandColor1}}>Options</ListSubheader>}
          >
            <ListItem sx={{mb:-1}}>
              <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="shared-config-name">Config Name</InputLabel>
                <FilledInput
                  id="shared-config-name"
                  value={cboxConfigName}
                  onChange={handleConfigName}
                  error={cboxConfigNameError}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleConfigNameUpdate}
                        onMouseDown={handleConfigNameUpdate}
                      >
                        <UpdateIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  inputRef={input => input}
                />
                <FormHelperText id="component-error-text">{cboxConfigNameHelperText}</FormHelperText>
              </FormControl>
            </ListItem>
            <ListItem sx={{mb:-1}}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-write-mode" primary="Include Values" secondary="Useful for troubleshooting or as additional information." />
              <Checkbox
                checked={cboxShareValues}
                onChange={cboxShareValuesTogglehandler}
                sx={{justifySelf:"left"}}/>
            </ListItem>
            <ListItem sx={{mb:-1}}>
              <ListItemIcon>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-write-mode" primary="Changed Parameters" secondary={cboxShareChangedParametersDisabled === true ? `To use this option, some parameters must be changed.` : `Only the changed parameters will be updated on the target.`}/>
              <Checkbox
                checked={cboxShareChangedParameters}
                onChange={cboxShareChangedParametersTogglehandler}
                disabled={cboxShareChangedParametersDisabled === true ? true : false}
                sx={{justifySelf:"left"}}/>
            </ListItem>
            {!cboxShareCredentialsProtectedParametersDisabled
              ? <ListItem sx={{mb:-1}}>
                    <ListItemIcon>
                      <SyncLockIcon />
                    </ListItemIcon>
                    <ListItemText id="switch-list-write-mode" primary="Authentication Data" secondary={cboxShareCredentialsProtectedParametersDisabled === true ? `To use this option, the app must be unlocked.` : `Allows access to locked parameters after import.`}/>
                    <Checkbox
                      checked={cboxShareCredentialsProtectedParameters}
                      onChange={cboxShareCredentialsProtectedTogglehandler}
                      disabled={cboxShareCredentialsProtectedParametersDisabled === true ? true : false}
                      sx={{justifySelf:"left"}}/>
                  </ListItem>
              : <></>
            }
          </List>
        </Box>
        { !fullScreen
          ? <DialogActions>
              <Button onClick={handleAbortClick} color="primary">
                Cancel
              </Button>
              <Button onClick={handlShareClick} disabled={cboxConfigNameError ? true : false} color="primary">
                Share
              </Button>
            </DialogActions>
          : <></>
        }
      </Dialog>
    </React.Fragment>
  );
}

export default ShareDialog;
