// Utils.js

function getUrlParameter (key) {
  const paramsURL = new URL(window.location.href);
  let params = new URL(paramsURL).searchParams;
  let value = params.get(key);
  return value;
}

function checkUrlParameter (key, value) {
  let currentValue = getUrlParameter(key);
  if (currentValue === value) {
    return true;
  } else {
    return false;
  }
}

function getBrandValue (url) {
  var subdomain = null;
  let hrefString = "https://"
  let urlIsHref = url.indexOf("://");

  if (urlIsHref > -1) {
    console.debug("getBrandValue | Parsing href url ...");
  } else {
    console.warn("getBrandValue | Fixing hostname to href url!");
    url = hrefString.concat(url);
    console.debug("getBrandValue | url:" ,url);
  }

  const paramsURL = new URL(url);
  let params = new URL(paramsURL).searchParams;
  let wltValue = params.get("WLT");
  console.debug("getBrandValue | WLT:", wltValue);

  if (wltValue) {
    console.warn("getBrandValue | Deprected WLT found and used!")
    // hoffmannm(30.01.2024) Switched from WLT to subdomain!
    return wltValue;
  } else {
    // Domain Level: customer.dev.nfc-config.app
    // [4]customer.[3]dev.[2]nfc-config.[1]app
    let domainLevel = url.split(".").length;

    if (domainLevel < 3) {
      console.warn("getBrandValue | No third level domains found!")
      return "unkown";
    } else {
      subdomain = url.split('.')[0].split('//')[1];
      console.debug("getBrandValue | subdomain found:", subdomain);
      return subdomain;
    }
  }
}

const getOriginUrl = () => {
  var href = window.location.href;
  var referrer = document.referrer;

  if(window.parent !== window) {
    console.debug(`getOriginUrl | Origin ${href} loaded inside iframe from ${referrer}.`)
    return referrer;
  } else {
    console.debug(`getOriginUrl | App loaded from origin: ${href}`)
    return href;
  }
}

const getOs = () => {
  const platform = (navigator.userAgentData?.platform || navigator.platform)?.toLowerCase();
  if(platform.startsWith("win")) return "windows";
  if(platform.startsWith("mac")) return "macos";
  if(platform.startsWith("linux")) return "linux";
  return "unknown";
}

function isPwa() {
  return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
  );
}

const isLocalhost = Boolean (
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export {
  getBrandValue,
  getOriginUrl,
  getUrlParameter,
  checkUrlParameter,
  getOs,
  isPwa,
  isLocalhost
};
