import  React, { useContext, useState, forwardRef} from 'react';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { AppContext } from '../contexts/context';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppAboutAppDialog(props) {
  const [open, setOpen] = useState(true);
  const [ state, dispatch ] = useContext(AppContext);
  const theme = useTheme(); 

  const handleClose = () => {
    dispatch({type: 'app/ui/menu/dialog/default/close'});
    setOpen(false);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ position: 'relative', backgroundColor:"#fff", mb:2 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{color:'#3b3b3b'}}/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color:"#3b3b3b" }} variant="h6" component="div">
              About App
            </Typography>
          </Toolbar>
        </Box>
        <Box sx={{paddingInline:2, paddingBottom:2}}> 
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                Legal Notice
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Information on usage rights and privileges</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography variant='subtitle1'>
              Verantwortungsbereich
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              Dieses Impressum gilt nur für die Internetpräsenz unter der Adresse www.kd-elektroniksysteme.de.
              </Typography>
            <Typography variant='subtitle1'>
              Abgrenzung
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              Diese Internetpräsenz ist Teil des WWW und dementsprechend mit fremden, sich jederzeit wandeln könnenden Websites verknüpft, welche folglich auch nicht diesem Verantwortungsbereich unterliegen und für die nachfolgenden Informationen nicht gelten.
Dass die Links weder gegen Sitten noch Gesetze verstoßen, wurde genau einmal geprüft, bevor sie hier aufgenommen wurden.
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              Zur Nutzung der Inhalte dieser Website bedarf es keiner Angabe von Daten durch den Nutzer. Personenbezogene Daten werden nur insofern erhoben, als sie der durch den Nutzer dieser Website gewünschten Kontaktaufnahme mit dem Betreiber dieser Website dienen. Sonstige nutzerbezogene Daten werden anonymisiert und teilweise pseudonymisiert für Zwecke der Webstatistik ausgewertet. Generell erfolgt keine Weitergabe von derart erhobenen statistischen Daten an Dritte.
              </Typography>
              <Typography variant='subtitle1'>
              Keine Haftung
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              Die Inhalte dieses Webprojektes wurden sorgfältig geprüft und nach bestem Wissen erstellt. Aber für die hier dargebotenen Informationen wird kein Anspruch auf Vollständigkeit, Aktualität, Qualität und Richtigkeit erhoben. Es kann keine Verantwortung für Schäden übernommen werden, die durch das Vertrauen auf die Inhalte dieser Website oder deren Gebrauch entstehen.
              </Typography>
              <Typography variant='subtitle1'>
              Schutzrechtsverletzung
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              Falls Sie vermuten, dass von dieser Website aus eines Ihrer Schutzrechte verletzt wird, teilen Sie dies bitte umgehend per elektronischer Post mit, damit zügig Abhilfe geschaffen werden kann. Bitte nehmen Sie zur Kenntnis: Die zeitaufwendigere Einschaltung eines Anwaltes zur für den Diensteanbieter kostenpflichtigen Abmahnung entspricht nicht dessen wirklichem oder mutmaßlichem Willen.
              </Typography>
              <Typography variant='subtitle1'>
              Webmaster
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              Wenn Sie technische Fragen oder Anregungen haben, nehmen Sie bitte Kontakt zum Webmaster dieser Homepage auf.
              </Typography>
              <Typography variant='subtitle1'>
              Quellennachweis der verwendeten Bilder
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              Die auf dieser Website verwendeten Bilder stammen bis auf die unten aufgeführten Ausnahmen aus eigener Produktion und unterliegen den Urheberrechten der Firma KD Elektroniksysteme GmbH.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>Privacy</Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Information of data protection policy
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography variant='subtitle1'>
            Datenschutz
              </Typography>
              <Typography variant='body2'>
              Der Betreiber dieser Website hält sich vollumfänglich an das deutsche Bundesdatenschutzgesetz (BDSG) und nach­geord­net an das Lan­des­da­ten­schutz­ge­setz des Landes Sachsen Anhalt (DSG-LSA) in der je­weils gülti­gen Fas­sung.
              </Typography>
              <Typography variant='body2'>
              Zur Nutzung der Inhalte dieser Website bedarf es keiner Angabe von Daten durch den Nutzer. Personenbezogene Daten werden nur insofern erhoben, als sie der durch den Nutzer dieser Website gewünschten Kontaktaufnahme mit dem Betreiber dieser Website dienen. Sonstige nutzerbezogene Daten werden anonymisiert und teilweise pseudonymisiert für Zwecke der Webstatistik ausgewertet. Generell erfolgt keine Weitergabe von derart erhobenen statistischen Daten an Dritte.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                Version
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Information on App version and updates
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Sec-Smart NFC App
              </Typography>
              <Typography>
                version 1.2.1
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Dialog>
    </div>
  );
}