// Context
import React, { useMemo, useState, useEffect, useContext } from 'react';

// Context
import { AppContext } from '../contexts/context';

// Material UI

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

// Firebase
// Todo(hoffmannm): move it to ServiceWorker?
// import { storage } from "./Firebase"
// import { ref, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-100px',
    width: '200px',
    height: '100px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
}));

// https://css-tricks.com/snippets/javascript/lighten-darken-color/
function LightenDarkenColor(col, amt) {

  var usePound = false;

  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}

export const ThemeController = ({ children }) => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [ loading, setLoading ] = useState(true);
  const [ serviceWorkerFinished, setServiceWorkerFinished ] = useState(false);
  const [ manifestLoaded, setManifestLoaded ] = useState(false);
  const [ manifestLink, setManifestLink ] = useState('/kde.webmanifest');
  const [ loadingState, setLoadingState ] = useState('...');

  const href = `image/appBrands.json`

  var brandValue = localStorage.getItem("brandValue");
  if (!brandValue) {
    console.warn(`ThemeController | Failed loading brandValue from local storage! Using default value: kde`)
    brandValue = 'kde';
  }

  var primaryLight = LightenDarkenColor(state.brandColor1, -40);
  var primaryDark = LightenDarkenColor(state.brandColor1, 40);
  var secondaryLight = LightenDarkenColor(state.brandColor2, -40);
  var secondaryDark = LightenDarkenColor(state.brandColor2, 40);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: primaryLight,
            main: state.brandColor1,
            dark: primaryDark
          },
          secondary: {
            light: secondaryLight,
            main: state.brandColor2,
            dark: secondaryDark
          },
        },
        overrides: {
          MuiCard: {
            root: {
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              marginBottom: 0,
            }
          },
        },
        components: {
          MuiFormControl: {
            styleOverrides: {
              root: {
                marginBottom: 15,
              },
            }
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontWeight: 500,
              },
            }
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                fontStyle: 'italic'
              }
            }
          }
        },
        spacing: 8,
      }),
    [state.brandColor1, state.brandColor2],
  );

  const appCycleCheck = () => {

    if ("serviceWorker" in navigator && process.env.NODE_ENV === 'production') {
      setLoadingState("Service Worker ...");
      navigator.serviceWorker.ready.then((registration) => {
        console.log(`ThemeController | appCycleCheck: A service worker is active:`, registration.active);
        setServiceWorkerFinished(true);
        // At this point, you can call methods that require an active
        // service worker, like registration.pushManager.subscribe()
      });
    } else {
      console.warn("ThemeController | appCycleCheck: Service workers are not supported.");
      setLoadingState("Service Worker skipped!");
      setServiceWorkerFinished(true);
    }

    // var manifestSelection = document.querySelector('#manifest-selection');
    window.addEventListener('load', function() {
      console.info("ThemeController | appCycleCheck: everything is finished");
      setManifestLoaded(true);
    });
  }

  const getManifestData=(href)=>{
    fetch(href, {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(function(response){
      return response.json();
    }).then(function(myJson) {
      console.log('ThemeController | manifestData:', myJson);
      dispatch({
        type: 'app/brand/manifest/updated',
        appName: myJson.name,
        appNameShort: myJson.short_name
      });
      console.debug("ThemeController | loading done")
      setLoading(false);
    });
  }

  const getBrandData=(href)=>{
    fetch(href, {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(function(response){
      return response.json();
    }).then(function(myJson) {

      let brandData = myJson.brands[brandValue];
      console.log('ThemeController | brandData:', brandData);
      console.log('ThemeController | brandData:', brandData.brandName);

      dispatch({
        type: 'app/brand/data/updated',
        brandName: brandData.Name,
        brandTitle: brandData.Title,
        brandColor1: brandData.Color1,
        brandColor2: brandData.Color2,
        brandOwner: brandData.Owner,
        brandStreet: brandData.Street,
        brandZip: brandData.ZipCode,
        brandCity: brandData.City,
        brandMail: brandData.Mail,
        brandPhone: brandData.Phone
      });
    });
  }

  useEffect(() => {
    appCycleCheck()
    if (serviceWorkerFinished) {
      setLoadingState("Theme ...");
      const href = `/appBrands.json`
      console.debug('ThemeController | brandValue:', brandValue);
      if (brandValue) {
        dispatch({type: 'app/brand/logoUrl/updated', value: `image/${brandValue}/logo.png`});
        setManifestLink(`/${brandValue}.webmanifest`);
      }
      getBrandData(href);

      if (manifestLoaded) {
        if (manifestLink !== 'notdefined') {
          console.log("ThemeController | manifestLink:", manifestLink);
          getManifestData(manifestLink);
        } else {
          console.error('No href for webmanifest found!')
        }
      }
    }
  }, [manifestLink, manifestLoaded, serviceWorkerFinished]);

  if (loading === true) {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
          <Typography sx={{
            marginTop: 2,
          }}>
            Loading {loadingState}
          </Typography>
        </div>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <>
          { children }
        </>
      </ThemeProvider>
    );
  }
}

