import React, { useEffect, useContext } from 'react';

// Reducer
import { AppContext } from '../contexts/context';

// containers
import { recordId } from './RecordData'

// Components
import { removeQueryParams } from './../components/AppUtils';

// Firebase
import { storage } from "./Firebase"
import { ref, getDownloadURL } from "firebase/storage";

// Tools
import LZString from 'lz-string';

const DataImport = () => {
  // global context
  const [ state, dispatch ] = useContext(AppContext);
  const myURL = new URL(window.location.href);
  var searchParams = new URLSearchParams(myURL.search);
  var queryCheck = false;
  // Firebase storage reference
  const storageRef = ref(storage);

  if (myURL.search.length >= 5) {
    if (searchParams.has('D')) {
      queryCheck = true;
      if (searchParams.has('N')) {
        state.uiImportConfigName = searchParams.get('N')
      }
      console.debug("DataImport: Data found!")
      dispatch({type: 'app/ui/dialog/default/show', value: 'import'});

    } else {
      queryCheck = false;
    }
  }

  function convertArraysToObjects(input) {
    const output = {};

    for (const key in input) {
      if (Array.isArray(input[key])) {
        output[key] = {};
        input[key].forEach((item, index) => {
          if (item !== '') {
            output[key][index.toString().padStart(3, '0')] = item;
          }
        });
      } else {
        output[key] = input[key];
      }
    }

    return output;
  }

  async function fetchSchemaData(url) {
    const response = await fetch(url);
    const result = await response.json();
    console.debug("DataImport Schema downloaded ... ");
    console.debug("DataImport Schema:", result);
    console.debug("DataImport Schema as JSON:", JSON.stringify(result));
    dispatch({type: 'data/S/updated', value: result});
  }

  function processDataImport(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key in recordId) {
          if (key === 'S') {
            // Process schema
            // TODO: Make a semVer check.
            console.debug(`DataImport Firebase: Download started ... `);
            getDownloadURL(ref(storageRef, `${obj[key]}/schema.json`))
            .then((url) => {
              console.log("DataImport URL:", url)
              fetchSchemaData(url);
            })
            .catch((error) => {
              console.error("DataImport Firebase: Download error: ", error)
              dispatch({
                type: 'app/ui/dialog/error',
                title: `Import Schema Error`,
                text: `Could not find file under the following path: '${obj[key]}'`
              });
            });
          } else {
            let subObj = {[key]: obj[key]};
            console.debug(`DataImport subObj`, subObj);

            dispatch({type: `data/${key}/updated`, value: subObj});
            dispatch({type: `ndef/control/updateScanTime`});
            if (!(Object.keys(obj[key]).length === 0 && obj[key].constructor === Object)) {
              console.debug(`DataImport Dispatch data/${key}/updated with value`, obj[key]);
            } else {
              console.warn(`DataImport ${key} is empty!`);
            }
          }
        }
      }
    }
  }

  const importDataByKeys = () => {
    let result = true;
    console.debug('DataImport: Try to restore data ... ')
    var compressedDataString = searchParams.get('D')
    console.debug('DataImport: compressedDataString', compressedDataString)
    var dataImportString = LZString.decompressFromEncodedURIComponent(compressedDataString);

    if (dataImportString !== null) {
      console.debug('DataImport: dataString', dataImportString)
      var dataImportObject = JSON.parse(dataImportString);
      if (dataImportObject !== null) {
        console.debug("DataImport: dataImportObject", dataImportObject);
        var dataimportRestoredObject = convertArraysToObjects(dataImportObject)

        if (dataimportRestoredObject !== null) {
          console.debug("DataImport: dataimportRestoredObject", dataimportRestoredObject);
          processDataImport(dataimportRestoredObject);
        } else {
          console.error('DataImport: Convert Array failed!')
          result = false;
        }
      } else {
        console.error('DataImport: Parsing data failed!')
        result = false;
      }
    } else {
      console.error('DataImport: Decompress failed!')
      result = false;
    }
    return result;
  }

  useEffect(() => {
    if (queryCheck) {
      console.debug(`DataImport: UseEffect`);
      if (!importDataByKeys()) {
        dispatch({
          type: 'app/ui/dialog/error',
          title: `Error importing data`,
          text: `An error has occurred while processing the data. Try again or ask for a new link.`
        });
      }
      removeQueryParams();
    }
  }, [ queryCheck ]);

  return (
    <div>
    </div>
  );

}

export default DataImport;
