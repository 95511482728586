import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AppContext } from '../contexts/context';


import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';

// Tools
import bcrypt from 'bcryptjs'

export default function UnlockDialog() {
  const [ state, dispatch ] = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [ helperText, setHelperText ] = useState('Wrong password. Try again or ask your service team.');
  const [muiLogin, setMuiLogin] = useState({
    password: '',
    showPassword: false,
  });
  const [isWrongPassword, setIsWrongPassword] = useState(false)

  useEffect(() => {
    if (state.uiDialog === 'unlock') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.uiDialog]);

  const handleClose = () => {
    setMuiLogin({ ...muiLogin, password: '', showPassword: false, });
    setIsWrongPassword(false)
    dispatch({type: 'app/ui/dialog/default/close'});
  };

  const handleLoginChange = (prop) => (event) => {
    setMuiLogin({ ...muiLogin, [prop]: event.target.value });
    if(isWrongPassword){
      setIsWrongPassword(false)
    }
  };

  const handleClickShowPassword = () => {
    setMuiLogin({ ...muiLogin, showPassword: !muiLogin.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Check user credentials
 const checkCredentials = useCallback(async() => {
    if (Object.keys(state.nfcDataCP).length) {
      var JSONnfcDataCP = JSON.stringify(state.nfcDataCP);
      var JSONnfcDataCPParsed = JSON.parse(JSONnfcDataCP);
      // console.debug("password:", muiLogin.password);

      if (Object.keys(state.nfcDataCP.CP).length) {
        if (state.nfcDataCP.CP.Hash) {
          if (bcrypt.compareSync(muiLogin.password, JSONnfcDataCPParsed.CP.Hash)) {
            console.info("User authenticated!")
            setMuiLogin({ ...muiLogin, password: '', showPassword: false, });
            dispatch({type: 'app/settings/userAuthorized', value: true});
            dispatch({type: 'app/ui/dialog/default/close'});
            dispatch({
              type: 'app/ui/snackbar/show',
              value: "Protected values and parameters successfully unlocked."
            });
          } else {
            setIsWrongPassword(true)
            console.info("User unauthenticated.");
            dispatch({type: 'app/settings/userAuthorized', value: false});
            setMuiLogin({ ...muiLogin, password: '', showPassword: false, });
          }
        } else {
          console.error("Error: No Authentication Data found: state.nfcDataCP.CP.Hash")
          setIsWrongPassword(true)
          setMuiLogin({ ...muiLogin, password: '', showPassword: false, });
          dispatch({type: 'app/settings/userAuthorized', value: false});
          setHelperText('Error: No Authentication Data found. Please scan again.');
        }
      } else {
        console.error("Error: No Authentication Data found: state.nfcDataCP.CP")
        setIsWrongPassword(true)
        setMuiLogin({ ...muiLogin, password: '', showPassword: false, });
        dispatch({type: 'app/settings/userAuthorized', value: false});
        setHelperText('Error: Faulty Authentication Data found. Please scan again.');
      }
    } else {
      console.error("Error: No Authentication Data found: state.nfcDataCP")
      setIsWrongPassword(true)
      setMuiLogin({ ...muiLogin, password: '', showPassword: false, });
      dispatch({type: 'app/settings/userAuthorized', value: false});
      setHelperText('Error: Faulty Authentication Data found. Please scan again.');
    }
  },[state.settingAuthenticated, muiLogin.password, state.nfcDataCP]);

  const keyUpSubmit=(e)=>{
    if(e.keyCode === 13){
      checkCredentials()
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-unlock">
        <DialogTitle id="form-dialog-unlock"
          sx={{display:"flex", flexDirection:"column", margin:0, alignItems:"center", justifyContent:"center"}}>
          Unlock
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mb:5}}>
            Enter password to show protected values and parameters.
          </DialogContentText>
          <FormControl fullWidth error={isWrongPassword?true:false} variant="filled">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <FilledInput
              autoFocus
              id="standard-adornment-password"
              type={muiLogin.showPassword ? 'text' : 'password'}
              value={muiLogin.password}
              onChange={handleLoginChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {muiLogin.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onKeyUp={e=>keyUpSubmit(e)}
              inputRef={input => input && input.focus()}
            />
            {isWrongPassword && <FormHelperText id="component-error-text">{helperText}</FormHelperText>}
          </FormControl>
        </DialogContent>
        <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={checkCredentials} color="primary" type='submit'>
                Confirm
              </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}
