import React, { useContext } from 'react';
import { AppContext } from '../contexts/context';

// Material UI

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@mui/styles';
import {useTheme} from '@mui/styles';
import ErrorIcon from '@mui/icons-material/Error';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import Zoom from '@mui/material/Zoom';

// Components
import { useGetLogo, useCountDataKeys, useCheckImportedData } from './AppUtils';

// Resources
import packageInfo from '../../package.json';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
    },
    appInfoCard: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      overflow: 'initial'
    },
    appInfoCardContent: {
      flex: '1 0 auto',
      display: 'flex',
      flexDirection: 'column'
    },
    attentionCard: {
      color: 'white',
    }
  }),
);

// Cards
export const TextCard = (props) => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.Title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.Text}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

export const AppInfoCard = (props) => {
  const [ state, dispatch ] = useContext(AppContext);
  var appEnv = 'prd'
  const theme = useTheme()

  var appLogo = useGetLogo();
  const appTitle = `About ${state.appManifestShortName}`


  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');

  if (subdomain.includes('remote') || subdomain.includes('dev') || isLocalhost) {
    appEnv = 'dev'
  } else if (subdomain.includes('stg') || subdomain.includes('staging')) {
    appEnv = 'stg'
  } else {
    appEnv = 'prd'
  }

  return (
    <Card sx={{
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        paddingX: 2,
        boxShadow: "none",
        alignItems: "left",
        justifyContent: "left"
      }}>
      <CardHeader
          avatar={
            <Avatar
              sx={{
                width:60,
                height:60,
                bgcolor: theme.palette.primary.dark,
                }}
              variant="rounded"
              aria-label="Logo"
              alt="Logo"
              src={appLogo}
            />
          }
          title={appTitle}
          subheader= { appEnv === 'prd'
                        ? <>v{packageInfo.version}-{process.env.REACT_APP_GIT_HASH}</>
                        : appEnv === 'stg'
                          ? <> Version: v{packageInfo.version} ({process.env.REACT_APP_GIT_HASH}-stg)</>
                          : <> Version: v{packageInfo.version} ({process.env.REACT_APP_GIT_HASH}-dev)</>
                      }
          titleTypographyProps={{marginLeft:0, color:"text.primary", variant:"h6", fontWeight: 'normal'}}
          sx={{mb:0}}
      />
      <CardContent sx={{mt:0, pt:0, pb:0}}>
        <Typography variant="body2" color="text.primary">
          {state.brandName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {state.brandTitle}
        </Typography>
      </CardContent>
    </Card>
  )
}

export const AttentionCard = (props) => {
  const theme = useTheme()

  return (
    <Zoom in={true} style={{ transitionDelay: '500ms'}}>
      <Card sx={{
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        paddingX: 2,
        boxShadow: "none",
        alignItems: "left",
        justifyContent: "left"
      }}>
        <CardContent sx={{mt:0, pb:0}}>
          <Typography gutterBottom variant="h5" component="h2" color="text.primary">
            {props.Title}
          </Typography>
          <Typography variant="body2" component="p" color="text.secondary">
            {props.Text}
          </Typography>
        </CardContent>
      </Card>
    </Zoom>
  )
}

export const AppInstallableCard = () => {
  const [ state, dispatch ] = useContext(AppContext);
  const theme = useTheme()

  const handleDismissClick = () => {
    dispatch({type: 'app/ui/card/appInstallable', value: 'dismissed'});
  }

  const handleInstallClick = () => {
    dispatch({type: 'app/ui/control/appInstallTrigger', value: true});
  }

  return (
    <Zoom in={true} style={{ transitionDelay: '500ms'}}>
      <Card sx={{
          border: '1px solid',
          borderColor: theme.palette.primary.main,
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          paddingX: 2,
          boxShadow: "none",
          alignItems: "left",
          justifyContent: "left"
        }}>
        <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#fff", padding:0.9 }} aria-label="status_icon">
                <DownloadForOfflineIcon color={"primary"} sx={{fontSize:50, color: theme.palette.primary.main}}/>
              </Avatar>
            }
            title="Information"
            subheader={"Install NFC Config"}
            titleTypographyProps={{marginLeft:0, color:"text.primary", variant:"h6", fontWeight: 'normal'}}
        />
        <CardContent sx={{mt:0, pt:0, pb:0}}>
          <Typography variant="body2" color="text.secondary">
            This will allow you to launch the app from the homescreen later, even when you are offline.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            onClick={handleInstallClick}
          >
            Install
          </Button>
          <Button
            color="primary"
            onClick={handleDismissClick}
          >
            Dismiss
          </Button>
        </CardActions>
      </Card>
    </Zoom>
  )
}

export const AppOpenCard = () => {
  const [ state, dispatch ] = useContext(AppContext);
  const theme = useTheme()

  const handleDismissClick = () => {
    dispatch({type: 'app/ui/card/appOpen', value: 'dismissed'});
  }

  const handleOpenApp = () => {
    window.open(state.currentURL, '_blank');
  }

  return (
    <Zoom in={true} style={{ transitionDelay: '500ms'}}>
      <Card sx={{
          border: '1px solid',
          borderColor: theme.palette.primary.main,
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          paddingX: 2,
          boxShadow: "none",
          alignItems: "left",
          justifyContent: "left"
        }}>
        <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#fff", padding:0.9 }} aria-label="status_icon">
                <DownloadDoneIcon color={"primary"} sx={{fontSize:50, color: theme.palette.primary.main}}/>
              </Avatar>
            }
            title="Information"
            subheader={"App is installed"}
            titleTypographyProps={{marginLeft:0, color:"text.primary", variant:"h6", fontWeight: 'normal'}}
        />
        <CardContent sx={{mt:0, pt:0, pb:0}}>
          <Typography variant="body2" color="text.secondary">
            The app was installed successfully. Switch from the browser view to the app if you like.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            onClick={handleOpenApp}
          >
            Open App
          </Button>
          <Button
            color="primary"
            onClick={handleDismissClick}
          >
            Dismiss
          </Button>
        </CardActions>
      </Card>
    </Zoom>
  )
}

export const NfcNotAvailableCard = (props) => {

  return (
    <Card sx={{
        border: '1px solid',
        borderColor: '#d50000',
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        paddingX: 2,
        boxShadow: "none",
        alignItems: "left",
        justifyContent: "left"
      }}>
      <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#fff", padding:0.9 }} aria-label="status_icon">
              <ErrorIcon color={"primary"} sx={{fontSize:50, color: '#d50000'}}/>
            </Avatar>
          }
          title="Important information"
          subheader="NFC not available"
          titleTypographyProps={{marginLeft:0, color:"text.primary", variant:"h6", fontWeight: 'normal'}}
      />
      <CardContent sx={{mt:0, pt:0, pb:0}}>
        <Typography variant="body2" color="text.secondary">
           This app requires an compatible device with Chrome and Android. To exchange data, please open the app on a supported device.
        </Typography>
      </CardContent>
    </Card>
  )
}

export const NfcNotGrantedCard = () => {
  const [ state, dispatch ] = useContext(AppContext);

  const handleGrantClick = () => {
    dispatch({type: 'app/ui/control/nfcGrantCheck', value: true});
  }

  const textSubTitle = {
    denied: "NFC devices blocked",
    prompt: "NFC devices blocked",
  }
  const textContent = {
    denied: "The use of NFC was denied on this device. Open the website settings and grant NFC permission to continue.",
    prompt: "No permission to use NFC on this device. Please grant permission to continue.",
  }

  return (
    <Card sx={{
        border: '1px solid',
        borderColor: '#d50000',
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        paddingX: 2,
        boxShadow: "none",
        alignItems: "left",
        justifyContent: "left"
      }}>
      <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#fff", padding:0.9 }} aria-label="status_icon">
              <ErrorIcon color={"primary"} sx={{fontSize:50, color: '#d50000'}}/>
            </Avatar>
          }
          title="Important information"
          subheader={textSubTitle[state.nfcPermission]}
          titleTypographyProps={{marginLeft:0, color:"text.primary", variant:"h6", fontWeight: 'normal'}}
      />
      <CardContent sx={{mt:0, pt:0, pb:0}}>
        <Typography variant="body2" color="text.secondary">
          {textContent[state.nfcPermission]}
        </Typography>
      </CardContent>
      { state.nfcPermission === 'prompt'
        ? <CardActions>
            <Button
              color="primary"
              onClick={handleGrantClick}
            >
              Grant permission
            </Button>
          </CardActions>
        : ''
      }
    </Card>
  )
}

export const ModifedDataCard = () => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  var dataCount = 0;
  var dataImported = false;

  dataCount = useCountDataKeys();
  dataImported = useCheckImportedData();

  if (Object.keys(state.nfcSchema)[0] && dataCount) {
    if (dataImported) {
      return (
        <>
          <Card className={classes.card}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Shared Data Imported
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Shared data has been imported and can be written to a compatible device.
              </Typography>
              <TextField
                id="data-source"
                label="Included parameters"
                defaultValue={dataCount}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
              />
            </CardContent>
          </Card>
        </>
      )
    } else {
      return (
        <>
          <Card className={classes.card}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Changes Overview
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Data has been changed since the last readout and can be written to a compatible device.
              </Typography>
              <TextField
                id="data-source"
                label="Changed parameters"
                defaultValue={dataCount}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
              />
            </CardContent>
          </Card>
        </>
      )
    }
  } else {
    return (
      <></>
    )
  }
}

export const DataInfoCard = () => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);

  if (Object.keys(state.nfcSchema)[0]) {
    return (
      <>
        <Card className={classes.card}>
          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="h2">
              Data Details                                  ,
            </Typography> */}
            <Typography variant="body2" color="textSecondary" component="p">
              Loaded Data
            </Typography>
            <TextField
              id="schema-version"
              label="Schema Version"
              defaultValue={state.nfcSchema.$id.split("/")[3]}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
            />
            <TextField
              id="data-source"
              label="Source of the data"
              defaultValue={state.nfcDataSource}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
            />
          </CardContent>
        </Card>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}

export const DeviceInfoCard = (props) => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);

  if (state.nfcTagSn !== null && Object.keys(state.nfcSchema)[0]) {
    return (
      <>
        <Card className={classes.card}>
          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="h2">
              About Device
            </Typography> */}
            <Typography variant="body2" color="textSecondary" component="p">
              Scanned device
            </Typography>
            <TextField
              id="article-code"
              label="Article Code"
              defaultValue={state.nfcSchema.$id.split("/")[2]}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
            />
            <TextField
              id="nfc-serial-number"
              label="NFC Serial Number"
              defaultValue={state.nfcTagSn}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
            />
            <TextField
              id="last-scan"
              label="Last Scan"
              defaultValue={state.nfcDataScanTime}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
            />
          </CardContent>
        </Card>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}
