import { useContext } from 'react';
import { AppContext } from '../contexts/context';
import * as fflate from 'fflate';
import { recordId } from './RecordData';

export function useNdefData () {

  const encoder = new TextEncoder();
  const [ state, dispatch ] = useContext(AppContext);

  return {
    updateWriteData: function () {
      var _nfcUri = state.nfcUri;
      var _nfcSchemaCompressed = state.nfcSchemaCompressed;
      var _nfcDataCP = encoder.encode(JSON.stringify(state.nfcDataCP))
      var _nfcDataP = encoder.encode(JSON.stringify(state.nfcDataP))
      var _nfcDataPP = encoder.encode(JSON.stringify(state.nfcDataPP))
      var _nfcDataPR = encoder.encode(JSON.stringify(state.nfcDataPR))
      var _nfcDataV = encoder.encode(JSON.stringify(state.nfcDataV))
      var _nfcDataVP = encoder.encode(JSON.stringify(state.nfcDataVP))

      console.info(`ndefData | updateWriteData originP:`, state.originP)
      console.info(`ndefData | updateWriteData nfcDataP:`, state.nfcDataP)
      console.info(`ndefData | updateWriteData originPP:`, state.originPP)
      console.info(`ndefData | updateWriteData nfcDataPP:`, state.nfcDataPP)
      console.info(`ndefData | updateWriteData originPR:`, state.originPR)
      console.info(`ndefData | updateWriteData nfcDataPR:`, state.nfcDataPR)
      console.info(`ndefData | updateWriteData nfcDataV:`, state.nfcDataV)
      console.info(`ndefData | updateWriteData nfcDataVP:`, state.nfcDataVP)

      const recUri = {
        id: recordId.Uri,
        recordType: "url",
        data: _nfcUri
      }

      const recSchema = {
        id: recordId.S,
        recordType: "mime",
        mediaType: "application/zip",
        data: _nfcSchemaCompressed
      }

      const recCP = {
        id: recordId.CP,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataCP
      };

      const recP = {
        id: recordId.P,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataP
      };

      const recPP = {
        id: recordId.PP,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataPP
      };

      const recPR = {
        id: recordId.PR,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataPR
      };

      const recV = {
        id: recordId.V,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataV
      };

      const recVP = {
        id: recordId.VP,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataVP
      };

      return {records: [
        recUri,
        recSchema,
        recCP,
        recP,
        recPP,
        recPR,
        recV,
        recVP
      ]}
    },
    checkTag: function ({message, serialNumber}) {
      dispatch({type: `data/checkTag/setStatus`, value: 'pending'});
      console.info("ndefData | CheckTag: Records found:  ", message.records.length);
      console.info("ndefData | CheckTag: Records:  ", message.records);

      var _checkResult = {
        message: 'checkSkippedAndDone',
        data: 'undefined',
        status: 'failed',
        sn: serialNumber,
        nfcUri: state.nfcUri,
        nfcSchema: state.nfcSchema,
        nfcSchemaCompressed: state.nfcSchemaCompressed,
        nfcDataCP: state.nfcDataCP,
        nfcDataP: state.nfcDataP,
        nfcDataPP: state.nfcDataPP,
        nfcDataPR: state.nfcDataPR,
        nfcDataV: state.nfcDataV,
        nfcDataVP: state.nfcDataVP,
      }

      if (state.settingForceWrite === false) {
        if (message.records.length === 0) {
          console.error("ndefData | checkTag: Empty tag found.");
          _checkResult.message = 'checkErrorEmptyTag';
          return _checkResult;
        }
      }
      // Check SerialNumber
      if (state.settingForceWrite === false) {
        if (state.settingSeamlessWrite === false) {
          if (state.nfcDataSource === 'scan') {
            if (state.nfcTagSn !== serialNumber) {
              console.info("ndefData | checkTag: Serial number not identical")
              console.info("ndefData | checkTag: device serialNumber", serialNumber)
              console.info("ndefData | checkTag: state  serialNumber", state.nfcTagSn)
              _checkResult.message = 'checkWarningDifferentSn';
              _checkResult.data = serialNumber;
              return _checkResult;
            }
          } else {
            console.debug("ndefData | CheckTag: Imported data, serial number check skipped.");
          }
        }
      } else {
        console.debug("ndefData | CheckTag: Force write active, record & SN check skipped.");
      }

      // Process Message
      for (const record of message.records) {
        const decoder = new TextDecoder();
        var urlData = null;
        var recordData = {};
        var recordDataJson = null;
        var recordKey = '';

        console.info("ndefData | CheckTag: Record Id:    " + record.id);
        console.info("ndefData | CheckTag: Record type:  " + record.recordType);
        console.info("ndefData | CheckTag: MIME type:    " + record.mediaType);

        switch (record.recordType) {
          case "mime":
            switch (record.mediaType) {
              case "application/json":
                console.info("ndefData | CheckTag: Processing MediaType JSON ...");
                console.info("ndefData | CheckTag: Record Data: ", record.data);
                recordDataJson = decoder.decode(record.data);
                try {
                  recordData = JSON.parse(decoder.decode(record.data));
                } catch (e) {
                  _checkResult.message = 'jsonParseError';
                  console.info("ndefData | readingHandler jsonParseError", _checkResult);
                  return _checkResult;
                }
                break;
              case "application/zip":
                console.info("ndefData | CheckTag: Processing MediaType ZIP ...");
                console.info("ndefData | CheckTag: Record Data: ", record.data);
                const recDataU8 = new Uint8Array(record.data.buffer);
                const decompressed = fflate.decompressSync(recDataU8);
                console.info("ndefData | CheckTag: Decompressed Data: ", decompressed);
                recordDataJson = decoder.decode(decompressed);
                try {
                  recordData = JSON.parse(recordDataJson);
                } catch (e) {
                  _checkResult.message = 'jsonParseError';
                  console.info("ndefData | readingHandler jsonParseError", _checkResult);
                  return _checkResult;
                }
                break;
              default:
                console.error(`CheckTag: Mediatype ${record.mediaType} not supported.`);
                _checkResult.message = 'checkErrorMediaTypeUnknown';
                _checkResult.data = record.mediaType;
                break;
            }

            if (state.settingForceWrite === false) {
              if (recordData === null || recordData === undefined) {
                console.error("ndefData | CheckTag: recordKey is null or undefined")
                _checkResult.message = 'checkErrorRecordKeyUnknown';
                return _checkResult;
              } else {
                recordKey = Object.keys(recordData)[0];
                console.info("ndefData | CheckTag: recordKey", recordKey)
              }
            } else {
              if (recordData === null || recordData === undefined) {
                console.warn("ndefData | CheckTag: recordKey is null or undefined")
                recordKey = '';
              } else {
                recordKey = Object.keys(recordData)[0];
                console.info("ndefData | CheckTag: recordKey", recordKey)
              }
            }

            if (recordKey === '$id') { // Check Schema ID
              if (recordData.$id === state.nfcSchema.$id) {
                console.info("ndefData | CheckTag: $id okay")
              } else {
                console.warn("ndefData | CheckTag: $id check failed")
                console.info("ndefData | CheckTag: old id", state.nfcSchema.$id)
                console.info("ndefData | CheckTag: new id", recordData.$id)
                // Check Device Code
                if (recordData.$id.split("/")[2] !== state.nfcSchema.$id.split("/")[2]) {
                  _checkResult.message = 'checkDeviceIdFailed';
                  _checkResult.data = recordData.$id.split("/")[2];
                  return _checkResult;
                }
                let srcVersion = (state.nfcSchema.$id.split("/")[3]).split(".")[0]
                let destVersion = (recordData.$id.split("/")[3])
                // Check Data Version
                if (destVersion.split(".")[0] !== srcVersion.split(".")[0]) {
                  console.debug(srcVersion.split(".")[0], "Old major version")
                  console.debug(destVersion.split(".")[0], "New major version")
                  _checkResult.message = 'checkVersionIdFailed';
                  _checkResult.data = destVersion;
                  return _checkResult;
                }
              }
              console.info("ndefData | CheckTag: Schema Updated ...");
              dispatch({type: 'data/S/updated', value: recordData});
              _checkResult.nfcSchemaCompressed = record.data;
            } else {
              // In the case that data was changed or imported via link are to be written,
              // then the read data must be combined with the changed data before writing.
              if ((!Object.keys(state.originP).length && Object.keys(state.nfcDataP).length)
               || (!Object.keys(state.originPR).length && Object.keys(state.nfcDataPR).length)
               || (!Object.keys(state.originPP).length && Object.keys(state.nfcDataPP).length)
              ) {
                console.info("ndefData | CheckTag: combining values!")
                if (recordKey in recordId) {
                  console.info(`ndefData | CheckTag: recordData - ${recordKey}`, recordData)
                  switch (recordKey) {
                    case 'CP':
                      _checkResult.nfcDataCP = recordData;
                      break;
                    case 'P':
                      recordData.P = {...recordData.P, ...state.nfcDataP.P};
                      _checkResult.nfcDataP = recordData;
                      break;
                    case 'PP':
                      recordData.PP = {...recordData.PP, ...state.nfcDataPP.PP};
                      _checkResult.nfcDataPP = recordData;
                      break;
                    case 'PR':
                      recordData.PR = {...recordData.PR, ...state.nfcDataPR.PR};
                      _checkResult.nfcDataPR = recordData;
                      break;
                    case 'V':
                      _checkResult.nfcDataV = recordData;
                      break;
                    case 'VP':
                      _checkResult.nfcDataVP = recordData;
                      break;
                    default:
                      console.error(`ndefData | CheckTag: unkown recordData - ${recordKey}`, recordData)
                      _checkResult.message = 'checkErrorRecordKeyUnknown';
                      _checkResult.data = recordKey;
                      break;
                  }
                } else {
                  console.error("ndefData | CheckTag: Unknown record data found: ", Object.keys(recordData)[0])
                  _checkResult.message = 'checkErrorRecordKeyUnknown';
                  _checkResult.data = recordKey;
                }
              }
            }
            break;
          case "url":
            urlData = decoder.decode(record.data);
            console.debug("ndefData | CheckTag: URL saved.", urlData)
            _checkResult.nfcUri = urlData;
            break;
          case "empty":
            console.error("ndefData | checkTag: Empty Record found found.");
            _checkResult.message = 'checkErrorRecordEmpty';
            _checkResult.data = record.recordType;
            break;
          case "smart-poster":
          default:
            console.debug(`ndefData | CheckTag: Recordtype ${record.recordType} not supported.`);
            _checkResult.message = 'checkErrorRecordTypeUnkown';
            _checkResult.data = record.recordType;
            break;
        }
      }

      _checkResult.status = 'tagOkay'
      console.debug('ndefData | CheckTag: _checkResult is', _checkResult)
      return _checkResult;
    },
    readingHandler: function ({message, serialNumber}) {
      // Process Tag SN
      console.info("ndefData | readingHandler Serial Number:", serialNumber);
      dispatch({type: 'data/nfcTagSnUpdated', value: serialNumber});

      const readResult = {
        message: 'undefined',
        data: 'undefined',
        status: 'failed',
        sn: serialNumber,
      }

      if (message.records.length === 0) {
        console.error("ndefData | readingHandler Empty tag found.");
        readResult.message = 'checkErrorEmptyTag';
        return readResult;
      } else {
        console.info("ndefData | readingHandler Records found:  " + message.records.length);
        // Process Message
        for (const record of message.records) {
          const decoder = new TextDecoder();
          var urlData = null;
          var recordData = {};
          var recordDataJson = null;

          console.info("ndefData | readingHandler Record Id:    " + record.id);
          console.info("ndefData | readingHandler Record type:  " + record.recordType);
          console.info("ndefData | readingHandler MIME type:    " + record.mediaType);

          switch (record.recordType) {
            case "mime":
              switch (record.mediaType) {
                case "application/json":
                  console.info("ndefData | readingHandler Processing MediaType JSON ...");
                  console.info("ndefData | readingHandler Record Data: ", record.data);
                  recordDataJson = decoder.decode(record.data);
                  try {
                    recordData = JSON.parse(decoder.decode(record.data));
                  } catch (e) {
                    readResult.message = 'jsonParseError';
                    readResult.status = 'failed';
                    console.info("ndefData | readingHandler jsonParseError", readResult);
                    return readResult;
                  }
                  break;
                case "application/zip":
                  console.info("ndefData | readingHandler Processing MediaType ZIP ...");
                  console.info("ndefData | readingHandler Record Data: ", record.data);
                  const recDataU8 = new Uint8Array(record.data.buffer);
                  const decompressed = fflate.decompressSync(recDataU8);
                  console.info("ndefData | readingHandler Decompressed Data: ", decompressed);
                  recordDataJson = decoder.decode(decompressed);
                  try {
                    recordData = JSON.parse(recordDataJson);
                  } catch (e) {
                    readResult.message = 'jsonParseError';
                    readResult.status = 'failed';
                    console.info("ndefData | readingHandler jsonParseError", readResult);
                    return readResult;
                  }
                  break;
                default:
                  console.warn(`ndefData | ERROR: Mediatype ${record.mediaType} not supported.`);
                  readResult.message = 'checkErrorMediaTypeUnknown';
                  readResult.data = record.mediaType;
                  break;
              }
              console.info("ndefData | readingHandler === data ===\n" + recordDataJson);
              console.info("ndefData | readingHandler === data ===\n", recordData);

              let recordKey = Object.keys(recordData)[0];

              if (recordKey === '$id') {
                console.info("ndefData | readingHandler Schema Updated ...");
                dispatch({type: 'data/S/updated', value: recordData});
                dispatch({type: 'data/S/updatedCompressed', value: record.data});
                readResult.status = 'done';
                readResult.message = 'readSuccessfullyDone';
              } else {
                if (recordKey in recordId) {
                  switch (recordKey) {
                    case 'P':
                    case 'PR':
                    case 'PP':
                      dispatch({type: `data/${recordKey}/readOut`, value: recordData});
                      break;
                    default:
                      dispatch({type: `data/${recordKey}/updated`, value: recordData});
                      break;
                  }
                  readResult.status = 'done';
                  readResult.message = 'readSuccessfullyDone';
                } else {
                  console.error("ndefData | readingHandler Unknown record data found: ",recordKey, Object.keys(recordData)[0])
                  readResult.status = 'failed';
                  readResult.message ='checkErrorRecordKeyUnknown';
                  readResult.data = recordKey;
                }
              }
              break;
            case "url":
              urlData = decoder.decode(record.data);
              console.debug("ndefData | readingHandler URL", urlData)
              dispatch({type: 'data/urlUpdated', value: urlData});
              readResult.status = 'done';
              readResult.message = 'readSuccessfullyDone';
              break;
            case "empty":
              console.info("ndefData | readingHandler Empty Record found. Skip or abort?!");
              readResult.message = 'checkErrorRecordEmpty';
              readResult.data = record.recordType;
              break;
            case "smart-poster":
            default:
              console.warn(`ndefData | readingHandler ERROR Recordtype ${record.recordType} not supported.`);
              readResult.message = 'checkErrorRecordTypeUnkown';
              readResult.data = record.recordType;
              break;
          }
        }
        return readResult;
      }
    }
  }
}
